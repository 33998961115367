
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import { urlWith, paramsWith } from './common.js';
import { CenterBox } from './components.js';
import { UserInfo, Organizations } from './context.js';

const ProtectedRoute = ({ rules }) => {
  const { userInfo } = useContext(UserInfo);
  const { organizations } = useContext(Organizations);

  if (userInfo == null) {
    return (
      <CenterBox>
        <CircularProgress size={64} />
      </CenterBox>
    );
  }

  for (let { id, predicate, newHash, newPath } of rules) {
    if (predicate({ userInfo, organizations })) {
      const redirectUrl = urlWith(
          window.location,
          {
            pathname: newPath,
            hash: newHash,
            search: paramsWith(
                window.location.search,
                { originalTarget: window.location.toString() }).toString(),
          });

      // DEBUG
      console.log({ id, location: window.location.toString(), redirectTo: redirectUrl.toString(), newPath, newHash });
      window.location.replace(redirectUrl.toString());
      break;
    }
  }
  return <Outlet />;
};

export default ProtectedRoute;
