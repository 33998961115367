
import { useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import { fetchUserInfo, urlWith } from '../common.js';
import { CenterBox } from '../components.js';
import { UserInfo, Messages } from '../context.js';

const SecretLoginView = () => {
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const { setMessage } = useContext(Messages);
  const { secretKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && !userInfo.unauthenticated) {
      const originalTarget = new URL(window.location).searchParams.get('originalTarget');
      window.location.href =
          originalTarget ?? urlWith(window.location, { pathname: '/app', hash: '/' }).toString();
    }
  }, [ userInfo ]);

  useEffect(() => {
    async function loginWithSecretKey() {
      if (!secretKey) return;

      const response = await fetch(`/auth/link?secretKey=${secretKey}`, { method: 'POST' });
      if (response.ok) {
        setUserInfo(await fetchUserInfo());
      } else {
        const errorMessage = await response.text();
        setMessage({ children: errorMessage, severity: 'error' });
        navigate('/login');
      }
    }
    loginWithSecretKey();
  }, [secretKey, setMessage, setUserInfo, navigate]);

  return (
    <CenterBox>
      <CircularProgress size={64} />
    </CenterBox>
  );
};

export default SecretLoginView;
