
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { renderDuration } from '../common.js';
import { SimpleButton } from '../components.js';
import { PlayIcon } from '../icons.js';
import { NO_TITLE, NO_CREATOR } from '../constants.js';

const ContentPreview = ({ content, onSwap }) => {
  const title = content.currentVersionData?.metadata?.title || NO_TITLE;
  const creator = content.currentVersionData?.metadata?.author
      || content.currentVersionData?.metadata?.publisher
      || content.currentVersionData?.metadata?.siteName
      || NO_CREATOR;

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%', padding: 2 }}>
      <SimpleButton onClick={() => onSwap(false)}>
        <Stack direction="column" sx={{ overflow: 'hidden', alignItems: 'start' }}>
          <Typography variant="h6"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '100%',
                display: 'block',
              }}>
            {title}
          </Typography>
          <Typography variant="subtitle1">By {creator}</Typography>
          <Typography variant="subtitle1">
            {renderDuration(content?.currentVersionData?.audio?.duration
                || content?.currentVersionData?.sections?.map(s => s.duration)?.sum())}
          </Typography>
        </Stack>
      </SimpleButton>
      <SimpleButton onClick={() => onSwap(true)} sx={{ flexShrink: 0, padding: 1 }}>
        <PlayIcon fontSize="large" />
      </SimpleButton>
    </Stack>
  );
};

export default ContentPreview;
