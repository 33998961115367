
import {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
  useCallback,
}
from 'react';
import mixpanel from 'mixpanel-browser';

import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { renderDuration, createId, range } from '../common.js';
import { SimpleButton, CenterBox } from '../components.js';
import { Messages, Audio } from '../context.js';
import { PlayIcon, ForwardIcon, RewindIcon, PauseIcon } from '../icons.js';

const sectionPosition = (position, durations) => {
  if (isNaN(position)) return { position: 0, index: 0 };

  let index = 0;
  while (position > durations[index]
      && index < durations.length - 1) {
    position -= durations[index];
    index++;
  }
  return { position, index };
};

const AudioPlayer = forwardRef(({
      sources,
      ads,
      hasFinalAd,
      preload,
      setOverrideTitle,
      contentId,
      organizationId,
      publicMode,
      sx,
    }, ref) => {

  const { setMessage } = useContext(Messages);
  const { play, stop, playing, playbackSpeed } = useContext(Audio);

  // The currently active audio segment is sources[activeSourceIndex]
  const [activeSourceIndex, setActiveSourceIndex] = useState(0);
  const [position, setPosition] = useState(0);
  const audioRef = useRef(null);

  // The currently active ad is ads[activeAdIndex % ads.length]
  const [activeAdIndex, setActiveAdIndex] = useState(0);
  // In publicMode, adMode is triggered when activeSourceIndex > activeAdIndex
  const [adMode, setAdMode] = useState(false);

  const id = useMemo(() => createId(), []);

  const duration = useMemo(() => sources.map(s => s.duration).sum(), [ sources ]);
  const adPositions = useMemo(() => Array.from(range(sources.length - 1))
        .map(index => sources.slice(0, index + 1).map(s => s.duration).sum())
        .concat(hasFinalAd ? [duration] : []), [ sources, duration, hasFinalAd ]);

  const handlePlay = useCallback((event) => {
    event?.stopPropagation();
    mixpanel.track('Play audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    play({ audioSource: audioRef.current, type: 'element', id });
  }, [id, play, contentId, organizationId, position]);

  const handleResume = useCallback(() => {
    play({ audioSource: audioRef.current, type: 'element', id });
  }, [id, play]);

  const handleSeek = useCallback((targetTime, event) => {
    if (adMode && publicMode) return;
    if (isNaN(duration)) return;

    const newTime = Math.max(Math.min(targetTime, duration), 0);

    if (event) {
      event.stopPropagation();
      mixpanel.track('Seek audio', {
        targetTime: newTime,
        contentId,
        organizationId,
      });
    }

    const { position: currentTime, index: newActiveSourceIndex } = sectionPosition(
        newTime, sources.map(s => s.duration));

    //console.log({ handleSeek: true, newTime, currentTime, newActiveSourceIndex, targetTime, duration });

    setActiveSourceIndex(newActiveSourceIndex);
    setPosition(newTime);

    // In publicMode, we trigger adMode based on how many ads the user has viewed and what
    // section they are trying to listen to. In publicMode, we never trigger adMode based on
    // seeking.
    if (ads?.length > 0 && newActiveSourceIndex > activeAdIndex && publicMode) {
      // Trigger ad mode
      setAdMode(true);
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
      }
    } else {
      // Jump to the selected time
      if (audioRef.current) {
        audioRef.current.currentTime = currentTime;
      }
    }
    if (playing?.id === id) {
      setTimeout(() => handleResume(), 0);
    }
  }, [
    duration,
    activeAdIndex,
    adMode,
    id,
    playing,
    sources,
    ads,
    handleResume,
    contentId,
    organizationId,
    publicMode,
  ]);

  const handleProgress = (event) => {
    if (!adMode) {
      setPosition(event.target.currentTime
          + sources.slice(0, activeSourceIndex).map(s => s.duration).sum());
    }
  };

  const handlePause = () => {
    mixpanel.track('Pause audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    stop({ id });
  };

  const handleEnded = (event) => {
    let done = false;

    //console.log({ handleEnded: true, activeAdIndex, activeSourceIndex, adMode });

    if (adMode) {
      mixpanel.track('Completed ad', {
        contentId,
        adId: ads[activeAdIndex % ads.length]?.id,
        organizationId,
      });

      const newActiveAdIndex = activeAdIndex + 1;
      setActiveAdIndex(newActiveAdIndex);

      // In publicMode, we play multiple ads together if the user tries to skip over multiple ads.
      // In non-publicMode, we only play one ad at a time.
      if (activeSourceIndex <= newActiveAdIndex || !publicMode) {
        setAdMode(false);

        if (activeSourceIndex < sources.length) {
          // Resume audio at `position`
          const { position: currentTime } = sectionPosition(position, sources.map(s => s.duration));
          audioRef.current.addEventListener('loadeddata', () => {
            audioRef.current.currentTime = currentTime;
          }, { once: true });
        } else {
          done = true;
          setActiveSourceIndex(0);
          setPosition(0);
        }
      } else {
        // We need to play another ad. Start back at time zero.
        audioRef.current.currentTime = 0;
      }
    } else {
      const newActiveSourceIndex = activeSourceIndex + 1;
      setActiveSourceIndex(newActiveSourceIndex);

      if (newActiveSourceIndex < sources.length) {
        // In publicMode, a user only hears an ad at each position once. In non-publicMode, a user
        // may hear an ad at each position multiple times.
        if (!publicMode) {
          // Reset activeAdIndex so that the appropriate ad position is shown on the seekbar
          setActiveAdIndex(activeSourceIndex);
        }

        if (ads?.length > 0 && (newActiveSourceIndex > activeAdIndex || !publicMode)) {
          mixpanel.track('Started ad', { contentId, adId: ads[activeAdIndex % ads.length].id, organizationId });
          setAdMode(true);
        }
      } else if (hasFinalAd) {
        setAdMode(true);
      } else {
        done = true;
      }
    }

    if (done) {
      mixpanel.track('Completed audio', { contentId, organizationId });
    } else {
      handleResume();
    }
  };

  useImperativeHandle(ref, () => ({
    play: handlePlay,
    pause: handlePause,
    seek: handleSeek,
  }));

  const handleError = (event) => {
    console.error(event.target.error);
    setMessage({ children: `MediaError code ${event.target.error?.code}`, severity: 'error' });
  };

  useEffect(() => {
    if (adMode) {
      setOverrideTitle?.(ads[activeAdIndex % ads.length]?.metadata?.title);
    } else {
      setOverrideTitle?.(null);
    }
  }, [ adMode, ads, activeAdIndex, setOverrideTitle ]);

  useEffect(() => {
    if (adMode) {
      audioRef.current.playbackRate = 1;
    } else {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [adMode, playbackSpeed]);

  // Ensure that activeSourceIndex is not out-of-bounds
  useEffect(() => {
    if (!adMode && activeSourceIndex >= sources.length) {
      setActiveSourceIndex(0);
    }
  }, [activeSourceIndex, sources, adMode]);

  //useEffect(() => console.log({ activeSourceIndex, activeAdIndex, adMode }), [activeSourceIndex, activeAdIndex, adMode]);

  return (
    <Stack direction="column" spacing={2} sx={sx}>

      <audio ref={audioRef}
          onError={handleError}
          onTimeUpdate={handleProgress}
          onEnded={handleEnded}
          src={adMode
              ? ads[activeAdIndex % ads.length]?.audio?.uri
              : sources[activeSourceIndex]?.uri}
          preload={(!!preload).toString()} />

      <Stack direction="column" sx={{ px: 2 }}>
        <Slider value={adMode ? adPositions[activeAdIndex] : position}
            size="small"
            max={duration || 1}
            step={0.1}
            valueLabelDisplay="auto"
            valueLabelFormat={renderDuration}
            marks={[
              {
                value: 0,
                label: renderDuration(position || 0),
              },
              {
                value: duration || 1,
                label: '-' + renderDuration(Math.max(duration - position, 0) || 0),
              },
            ].concat(adPositions.map(p => ({ value: p })))}
            sx={{
              '.MuiSlider-thumb': {
                transition: 'left 0.5s linear',
              },
              '.MuiSlider-track': {
                transition: 'width 0.5s linear',
              },
              '.MuiSlider-markLabel': {
                fontSize: 10,
              },
            }}
            onChange={(event) => handleSeek(event.target.value, event)} />
        <CenterBox sx={{ mt: -2 }}>
          <Typography variant="caption">
            {renderDuration((Math.max(duration - position, 0) / playbackSpeed) || 0)} left ({playbackSpeed}x)
          </Typography>
        </CenterBox>
      </Stack>

      <Stack direction="row"
          spacing={8}
          sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>

        <SimpleButton onClick={(event) => handleSeek(position - 10, event)} disabled={adMode}>
          <RewindIcon sx={{ fontSize: 32 }} />
        </SimpleButton>

        {playing?.id === id
            ? <SimpleButton onClick={handlePause}>
                <PauseIcon sx={{ fontSize: 64 }} />
              </SimpleButton>
            : <SimpleButton onClick={handlePlay}>
                <PlayIcon sx={{ fontSize: 64 }} />
              </SimpleButton>}

        <SimpleButton onClick={(event) => handleSeek(position + 10, event)} disabled={adMode}>
          <ForwardIcon sx={{ fontSize: 32 }} />
        </SimpleButton>

      </Stack>
    </Stack>
  );
});

export default AudioPlayer;
