
import { useEffect, useContext, useMemo, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { urlWith, paramsWith } from '../common.js';
import { ActionButton } from '../components.js';
import { UserInfo, Messages } from '../context.js';
import { GoogleIcon } from '../icons.js';
import SimpleHeaderBar from '../SimpleHeaderBar.js';
import { EMAIL_REGEX } from '../constants.js';

const LoginView = () => {
  const [searchParams] = useSearchParams();
  const { userInfo } = useContext(UserInfo);
  const { setMessage } = useContext(Messages);

  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (userInfo && !userInfo.unauthenticated) {
      window.location.href =
          urlWith(window.location, { pathname: '/app', hash: '/' }).toString();
    }
  }, [ userInfo ]);

  const authUrl = useMemo(() => {
    // NOTE: authUrl inherits queryParam originalTarget from window.location
    let url = urlWith(
        window.location,
        {
          pathname: '/auth/google',
          hash: '',
        });
    // Rewrite localhost port so the local dev server can login correctly
    if (url.host.startsWith('localhost:4000')) {
      url = urlWith(url, {
        host: 'localhost:3000',
        // TODO: Pass through originalTarget, do not overwrite
        search: paramsWith(url.search,
            { originalTarget: `http://${window.location.host}/app` }),
      });
    }
    return url.toString();
  }, []);

  const validEmail = useMemo(() => !!EMAIL_REGEX.test(email), [email]);

  const handleSendLink = async () => {
    setSending(true);
    const response = await fetch('/api/loginLink', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    setSending(false);
    if (response.ok) {
      setMessage({ children: 'Login link sent! Check your inbox', severity: 'success' });
    } else {
      const errorMessage = await response.text();
      setMessage({ children: errorMessage, severity: 'error' });
    }
  };

  return (
    <Stack direction="column"
        sx={{ width: '100%', alignItems: 'center', minWidth: 1000, height: '100%' }}
        spacing={6}>

      <SimpleHeaderBar actionButton={
          <ActionButton color="white"
              size={30}
              sx={{
                border: '1px solid black',
                boxShadow: 'none',
                fontWeight: 500,
              }}
              href="#">
            Sign up
          </ActionButton>
      } />

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Stack direction="column" spacing={2} sx={{ width: 'fit-content', alignItems: 'center' }}>
          <Typography variant="h4" fontWeight={700}>Login</Typography>
          <Typography variant="body2">
            We&apos;ll email you a magic link for a password free sign in
          </Typography>
          <TextField size="small"
              fullWidth
              label="Email"
              onChange={(event) => setEmail(event.target.value)} />
          <ActionButton onClick={handleSendLink}
              disabled={!validEmail || sending}
              sx={{ minWidth: '100%' }}>
            {sending ? <CircularProgress size={32} /> : 'Send link'}
          </ActionButton>
          <Typography variant="body2">
            <br/><br/>Use Google instead
          </Typography>
          <ActionButton href={authUrl} sx={{ minWidth: '100%' }}>
            <Stack direction="row" spacing={2}>
              <GoogleIcon fontSize="small" />
              <Typography fontWeight={700}>Sign in with Google</Typography>
            </Stack>
          </ActionButton>
          {searchParams.get('permissionDenied') && <Typography color="error" variant="caption">
            Login failed.
          </Typography>}
        </Stack>
      </Box>

    </Stack>
  );
};

export default LoginView;
