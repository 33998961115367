
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Stack direction="column"
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
      <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
        <Box>
          {error
              ? error.message
              : 'Something went wrong!'}
        </Box>
        {resetErrorBoundary && <Box><Link onClick={resetErrorBoundary}>Try again</Link></Box>}
        <Box><Link href="#/">Back to VoiceTopics</Link></Box>
      </Stack>
    </Stack>
  );
};

export default ErrorFallback;
