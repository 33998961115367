
// TODO: Update all colors to match mocks
export const publishStatusList = ['PUBLISHED', 'UNPUBLISHED'];
export const publishStatusColors = {
  UNPUBLISHED: 'inProgress',
  PUBLISHED: 'completed',
};

export const creationStatusList = ['DRAFT', 'ARCHIVED', 'COMPLETED', 'DELETED'];
export const creationStatusColors = {
  DRAFT: 'gray',
  ARCHIVED: 'gray',
  COMPLETED: 'gray',
  DELETED: 'failed',
};

export const processingStatusList = ['PROCESSING', 'COMPLETED', 'ERROR'];
export const processingStatusMap = {
  REQUESTED: 'PROCESSING',
  SCRAPED: 'PROCESSING',
  EDITED: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

export const NO_TITLE = 'Untitled';
export const NO_CREATOR = 'Unknown';
export const NO_GROUP = 'Unassigned';

export const VOICE_OPTIONS = {
  default: [
    'openai/echo',
    'openai/shimmer',
    'openai/alloy',
    'openai/fable',
    'openai/onyx',
    'openai/nova',
    'polly/Danielle',
    'polly/Gregory',
    'polly/Ruth',
  ],
  fullAccess: [
    'openai/echo',
    'openai/shimmer',
    'openai/alloy',
    'openai/fable',
    'openai/onyx',
    'openai/nova',
    'elevenlabs/Sarah',
    'elevenlabs/Charlie',
    'elevenlabs/Callum',
    'elevenlabs/Charlotte',
    'elevenlabs/Matilda',
    'elevenlabs/Jessica',
    'elevenlabs/Chris',
    'elevenlabs/Daniel',
    'elevenlabs/Bill',
    'elevenlabs/Laura',
    'elevenlabs/George',
    'elevenlabs/Liam',
    'elevenlabs/Alice',
    'elevenlabs/Will',
    'elevenlabs/Eric',
    'elevenlabs/Brian',
    'elevenlabs/Lily',
    'polly/Danielle',
    'polly/Gregory',
    'polly/Ruth',
  ],
};

export const DEFAULT_VOICE = 'openai/echo';

export const NO_GROUP_ID = 'none';

export const EMAIL_REGEX = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,63}$/; // eslint-disable-line

