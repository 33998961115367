
import { useState, useRef, useContext, forwardRef, useMemo } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import mixpanel from 'mixpanel-browser';

import Box from '@mui/material/Box';
import BugReportIcon from '@mui/icons-material/BugReport';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Typography from '@mui/material/Typography';

import { toTitleCase, timeOperation } from '../common.js';
import {
  creationStatusList,
  creationStatusColors,
  publishStatusList,
  publishStatusColors,
  NO_TITLE,
  NO_CREATOR,
} from '../constants.js';
import { UserInfo, Messages, Audio } from '../context.js';
import { SmallChip, SimpleButton, DarkMenu } from '../components.js';
import { PlaybackSpeedIcon } from '../icons.js';
import AudioPlayer from '../audio/AudioPlayer.js';

dayjs.extend(duration);

const ContentPlayer = forwardRef(({
      content,
      setContent,
      version,
      gridMode,
      editMode,
      publicMode,
      dateMode,
      ads,
      preload,
      noLink,
      sx,
    },
    ref) => {

  // DEBUG
  //console.log({ content, version, ads });

  const { userInfo, impersonationUser } = useContext(UserInfo);
  const { setMessage } = useContext(Messages);
  const { playbackSpeed, setPlaybackSpeed } = useContext(Audio);

  const [creationStatusLoading, setCreationStatusLoading] = useState(false);
  const [publishStatusLoading, setPublishStatusLoading] = useState(false);

  const [creationStatusMenuOpen, setCreationStatusMenuOpen] = useState(false);
  const creationStatusRef = useRef(null);
  const handleCreationStatusMenuOpen = (event) => {
    event.stopPropagation();
    if (content.status === 'COMPLETED') {
      setCreationStatusMenuOpen(true);
    }
  };
  const handleCreationStatusMenuClose = (event) => {
    event.stopPropagation();
    setCreationStatusMenuOpen(false);
  };

  const [publishStatusMenuOpen, setPublishStatusMenuOpen] = useState(false);
  const publishStatusRef = useRef(null);
  const handlePublishStatusMenuOpen = (event) => {
    if (content.creationStatus === 'COMPLETED') {
      setPublishStatusMenuOpen(true);
    } else {
      setMessage({
        children: 'VoiceTopics must be Completed before they can be Published',
        severity: 'warning',
      });
    }
  }
  const handlePublishStatusMenuClose = (event) => {
    event.stopPropagation();
    setPublishStatusMenuOpen(false);
  };

  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const moreRef = useRef(null);
  const handleMoreMenuClose = (event) => {
    event.stopPropagation();
    setMoreMenuOpen(false);
  };

  // Used by AudioPlayer to override the title appearance during ad presentation
  const [ overrideTitle, setOverrideTitle ] = useState(null);

  const handleStatusUpdate = async (event, { publishStatus, creationStatus }) => {
    event.stopPropagation();

    setPublishStatusMenuOpen(false);
    setCreationStatusMenuOpen(false);

    if (publishStatus) {
      setPublishStatusLoading(true);
    }
    if (creationStatus) {
      setCreationStatusLoading(true);
    }

    const headers = { 'Content-Type': 'application/json' };
    if (impersonationUser) {
      headers.impersonate = impersonationUser;
    }
    const [loadTime, response] = await timeOperation(() => fetch(`/api/content/${content.id}/status`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ publishStatus, creationStatus }),
    }));

    setPublishStatusLoading(false);
    setCreationStatusLoading(false);

    if (response.ok) {
      mixpanel.track('Updated status', { loadTime });
      setContent?.(await response.json());
      setMessage({
        children: `Status updated: ${toTitleCase(publishStatus || creationStatus)}`,
        severity: 'success',
      });
    } else {
      const errorMessage = await response.text();
      mixpanel.track('Error updating status', { loadTime, errorMessage });
      setMessage({ children: errorMessage, severity: 'error' });
    }
  };

  const bottomBar = (
    <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {(!dateMode || dateMode === 'modifiedTime')
            && <Typography variant="caption">
              Last modified: {dayjs(content.modifiedTime).format('M/D/YYYY')}
            </Typography>}
        {dateMode === 'createdTime'
            && <Typography variant="caption">
              Created: {dayjs(content.createdTime).format('M/D/YYYY')}
            </Typography>}
        {dateMode === 'publishDate' && content.publishDate
            && <Typography variant="caption">
              Published: {dayjs(content.publishDate).format('M/D/YYYY')}
            </Typography>}
      </Box>
      {!noLink && <SimpleButton href={`/#/listen/${content.id}`}
          target="_blank"
          sx={{ padding: 0.5 }}
          disabled={!publicMode && (content.creationStatus !== 'COMPLETED'
              || content.publishStatus !== 'PUBLISHED'
              || content.status !== 'COMPLETED')}>
        <LinkIcon />
      </SimpleButton>}
    </Stack>
  );

  const versionData = useMemo(() => version || content.currentVersionData, [version, content]);

  const sources = useMemo(() =>
      versionData?.sections ? versionData.sections : [versionData?.audio],
      [versionData]);

  const title = versionData?.metadata?.title || NO_TITLE;
  const creator = versionData?.metadata?.author
      || versionData?.metadata?.publisher
      || versionData?.metadata?.siteName
      || NO_CREATOR;

  let daysRemaining;
  if (content.creationStatus === 'DELETED') {
    const cleanupTime = dayjs(content.deletedTime).add(30, 'day');
    daysRemaining = Math.ceil(dayjs.duration(Math.max(0, cleanupTime.diff(dayjs()))).asDays());
  }

  return (
    <Stack direction={gridMode ? 'column' : 'row'}
        spacing={2}
        sx={{ width: '100%', justifyContent: 'space-between', ...sx }}>
      <Stack direction="column" spacing={1} sx={{ width: gridMode ? '100%' : '35%' }}>
        <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'space-between' }}>

          {publicMode
              ?  <Typography variant="h6"
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '100%',
                      display: 'block',
                    }}>
                  {overrideTitle || title}
                </Typography>
              : <SimpleButton href={`#/edit/${content.id}`}>
                  <Typography variant="h6"
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '100%',
                        display: 'block',
                      }}>
                    {overrideTitle || title}
                  </Typography>
                </SimpleButton>}

          <SimpleButton ref={moreRef} onClick={() => setMoreMenuOpen(true)} sx={{ flexShrink: 0 }}>
            <MoreVertIcon fontSize="large" />
            <DarkMenu anchorEl={moreRef.current}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={moreMenuOpen}
                onClose={handleMoreMenuClose}
                MenuListProps={{ dense: true }}>
              {!editMode && !publicMode && <MenuItem dense={true}>
                <SimpleButton href={`#/edit/${content.id}`}
                    sx={{ width: '100%', justifyContent: 'start' }}>
                  <Stack direction="row" spacing={1}>
                    <EditIcon fontSize="small" />
                    <Box>Edit</Box>
                  </Stack>
                </SimpleButton>
              </MenuItem>}
              <MenuItem dense={true}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <PlaybackSpeedIcon fontSize="small" />
                    <Box>Playback speed</Box>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                    <SimpleButton onClick={() => setPlaybackSpeed(playbackSpeed - 0.25)}>
                      <ChevronLeftIcon fontSize="small" />
                    </SimpleButton>
                    <Box sx={{ minWidth: 24, textAlign: 'center' }}>{playbackSpeed}</Box>
                    <SimpleButton onClick={() => setPlaybackSpeed(playbackSpeed + 0.25)}>
                      <ChevronRightIcon fontSize="small" />
                    </SimpleButton>
                  </Stack>
                </Stack>
              </MenuItem>
              {!publicMode && userInfo?.admin && <MenuItem dense={true}>
                <SimpleButton href={`#/debug/${content.id}`}
                    sx={{ width: '100%', justifyContent: 'start' }}>
                  <Stack direction="row" spacing={1}>
                    <BugReportIcon fontSize="small" />
                    <Box>Debug</Box>
                  </Stack>
                </SimpleButton>
              </MenuItem>}
              {!publicMode && userInfo?.admin && <MenuItem dense={true}>
                <SimpleButton href={`#/admin?tab=Prompt&contentId=${content.id}`}
                    sx={{ width: '100%', justifyContent: 'start' }}>
                  <Stack direction="row" spacing={1}>
                    <TuneOutlinedIcon fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
                    <Box>Tune</Box>
                  </Stack>
                </SimpleButton>
              </MenuItem>}
              {!publicMode && versionData?.status === 'RECORDED' &&
                  <MenuItem dense={true}
                      onClick={() => {
                        if (versionData?.sections) {
                          setMessage({
                            children: 'Note: Downloaded audio does not contain any ads',
                            severity: 'warning',
                          });
                        }
                      }}>
                    <SimpleButton href={versionData?.audio?.uri}
                        download
                        sx={{ width: '100%', justifyContent: 'start' }}>
                      <Stack direction="row" spacing={1}>
                        <FileDownloadOutlinedIcon fontSize="small" />
                        <Box>Download</Box>
                      </Stack>
                    </SimpleButton>
                  </MenuItem>}
              {!publicMode && versionData?.creationStatus !== 'DELETED' &&
                  <MenuItem dense={true}
                      onClick={(event) => handleStatusUpdate(event, { creationStatus: 'DELETED' })}>
                    <Stack direction="row" spacing={1}>
                      <DeleteIcon fontSize="small" />
                      <Box>Delete</Box>
                    </Stack>
                  </MenuItem>}
              {content.url && <MenuItem dense={true}
                  onClick={() => window.open(content.url, '_blank')}>
                <Stack direction="row" spacing={1}>
                  <LinkIcon fontSize="small" />
                  <Box>Original</Box>
                </Stack>
              </MenuItem>}
            </DarkMenu>
          </SimpleButton>

        </Stack>

        <Typography variant="subtitle1">
          {overrideTitle ? <>&nbsp;</> : `By ${creator}`}
        </Typography>

        {!publicMode && <Stack direction="row" spacing={1}>

          {(content.status === 'COMPLETED' || content.creationStatus === 'DELETED')
              && <SmallChip id="creationStatus"
                  icon={creationStatusLoading ? <CircularProgress size={16} /> : undefined}
                  label={toTitleCase(content.creationStatus)
                      + (content.creationStatus === 'DELETED' ? `: ${daysRemaining} days to undo` : '')}
                  color={creationStatusColors[content.creationStatus]}
                  ref={creationStatusRef}
                  onClick={handleCreationStatusMenuOpen} />}
          <DarkMenu anchorEl={creationStatusRef.current}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              transformOrigin={{ horizontal: 'left', vertical: 8 }}
              open={creationStatusMenuOpen}
              onClose={handleCreationStatusMenuClose}
              MenuListProps={{ dense: true }}
              sx={{
                '& .MuiMenu-list': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }
              }}>
            {creationStatusList.map((creationStatus) => (
              <MenuItem dense={true}
                  key={creationStatus}
                  onClick={(event) => handleStatusUpdate(event, { creationStatus })}>
                <SmallChip label={toTitleCase(creationStatus)} color={creationStatusColors[creationStatus]} />
              </MenuItem>
            ))}
          </DarkMenu>

          {content.status === 'COMPLETED' && <SmallChip id="publishStatus"
              icon={publishStatusLoading ? <CircularProgress size={16} /> : undefined}
              label={toTitleCase(content.publishStatus)}
              color={publishStatusColors[content.publishStatus]}
              ref={publishStatusRef}
              onClick={handlePublishStatusMenuOpen} />}
          <DarkMenu anchorEl={publishStatusRef.current}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              transformOrigin={{ horizontal: 'left', vertical: 8 }}
              open={publishStatusMenuOpen}
              onClose={handlePublishStatusMenuClose}
              MenuListProps={{ dense: true }}
              sx={{
                '& .MuiMenu-list': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }
              }}>
            {publishStatusList.map((publishStatus) => (
              <MenuItem dense={true}
                  key={publishStatus}
                  onClick={(event) => handleStatusUpdate(event, { publishStatus })}>
                <SmallChip label={toTitleCase(publishStatus)}
                    color={publishStatusColors[publishStatus]} />
              </MenuItem>
            ))}
          </DarkMenu>

          {(content.currentVersionData.status === 'REQUESTED'
                  || content.currentVersionData.status === 'SEGMENTED')
              && <SmallChip label="Processing" color="gray" />}

          {content.currentVersionData.status === 'ERROR' &&
              <SmallChip label="Error" icon={<ErrorOutlineIcon />} color="failed" />}

        </Stack>}

        {!gridMode && bottomBar}
      </Stack>

      {versionData?.status === 'RECORDED'
          && <AudioPlayer sources={sources}
                hasFinalAd={versionData.hasFinalAd}
                publicMode={publicMode}
                preload={preload}
                ads={ads}
                sx={{ width: gridMode ? '100%' : '65%' }}
                setOverrideTitle={setOverrideTitle}
                contentId={content.id}
                organizationId={content.organization}
                ref={ref} />}

      {gridMode && bottomBar}
    </Stack>
  );
});

export default ContentPlayer;
